import React, { useRef, useLayoutEffect } from 'react';
import { graphql, Link, PageProps } from 'gatsby';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
// import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/seo';
import links from '../resources/links.js';
import Header from '../components/header/Header';
import Container from '../hoc/container/Container';
import NavButton from '../components/navigation/navButton/NavButton';
import Button from '../components/ui/button/Button';

import style from './index.module.css';

import eaPlane from '../assets/images/ea-plane.jpg';

gsap.registerPlugin(ScrollTrigger);

// TODO: Need to test if the filter will work for nested values such as the weekly price inside the prices object.
export const query = graphql`
	query IndexPageQuery {
		site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
			title
			description
			keywords
		}
	}
`;

// TODO: Add to CMS
const INFO_DATA = [
	{
		heading: 'Exclusive Luxury In Private Locations',
		copy: 'Superior Yachting specialises in organising luxury charters for sail and motor yachts around the world.',
		link: {
			label: 'Browse Yachts',
			to: '/browse'
		},
		imageUrl:
			'https://cdn.sanity.io/images/o5bo5dba/production/394a3840ed663b258b42e7eb1e070af302adf545-1440x960.jpg?w=1000&h=1000&fit=max'
	},
	{
		heading: 'Your Time Should Be Spent Enjoying The Retreat',
		copy:
			'Leave the planning to us. Whether it’s partying the night away on your favourite Greek island, indulging in local cuisines of historical towns, or sipping cocktails on Cote D`azur... We’ve got you covered!',
		link: {
			label: 'Download the Brochure',
			to: '/SY-Brochure-2020.pdf'
		},
		imageUrl:
			'https://cdn.sanity.io/images/o5bo5dba/production/2d5fb63c2ed26ae4e8febc4210a201f9a3b1f77b-1440x1055.jpg?w=1000&h=1000&fit=max'
	},
	{
		heading: 'Expertly Tailored To Your Needs',
		copy: 'Our mission is to craft unforgettable experiences for you and your guests.',
		link: {
			label: 'Enquire Now',
			to: '/contact'
		},
		imageUrl:
			'https://cdn.sanity.io/images/o5bo5dba/production/6022c9405bb837b7e9f63b9881eb5932e138ffbd-1200x855.jpg?w=1000&h=1000&fit=max'
	}
];

const PROCESS_DATA = [
	{
		heading: 'Complete Consultation Form',
		detail: "We get to know you and your goals for the trip. You'll be assigned a dedicated experience manager."
	},
	{
		heading: 'Get A Hand Picked Selection',
		detail:
			"We will send you a variety of yachts that match your requirements. Note that many yachts aren't available on our website just yet, so please make an enquiry."
	},
	{
		heading: 'Pick Your Yacht and Locations',
		detail: 'Learn more about the Yachts. Detail your personal requirements and make any additional special requests.'
	},
	{
		heading: 'Save Money With Our Reduced Fees',
		detail:
			'Modern technology and working practices means we will offer you the best possible rates. Request a comparison quote to see for yourself.'
	},
	// {
	// 	heading: 'Private Jet There (Optional add-on)',
	// 	detail: 'Superior Yachting are the only broker to offer a unified travel experience.',
	// },
	{
		heading: 'Party and Unwind in Superior Style!',
		detail: ''
	}
];

const IndexPage: React.FC<PageProps> = props => {
	const processSection = useRef<HTMLElement>(null);
	const processImg = useRef<HTMLDivElement>(null);
	const processList = useRef<HTMLUListElement>(null);

	// Adds parallax effect on scroll
	useLayoutEffect(() => {
		gsap.utils.toArray('.parrallaxBlock').forEach((div: any, i: number) => {
			const overlay = div.querySelector('div');
			const image = div.querySelector('img');
			gsap.from(overlay, {
				yPercent: 120,
				ease: 'none',
				scrollTrigger: {
					start: 'top bottom',
					end: 'top top',
					trigger: div as HTMLDivElement,
					scrub: 1
				}
			});
			gsap.from(image, {
				opacity: 0.2,
				ease: 'none',
				scrollTrigger: {
					start: 'top bottom',
					end: 'bottom bottom',
					trigger: div as HTMLDivElement,
					scrub: true
				}
			});
		});
	}, []);

	// Process Section
	useLayoutEffect(() => {
		// pinImageTrigger.current =
		ScrollTrigger.create({
			trigger: processSection.current,
			start: 'top top',
			end: 'bottom top',
			pin: processImg.current!
		});
		// TODO: rename Ref of container
		gsap.to('#processImg', {
			scale: 1.2,
			ease: 'none',
			scrollTrigger: {
				start: 'top top',
				end: 'bottom bottom',
				trigger: processSection.current,
				scrub: true
			}
		});
	}, [processImg.current, processSection.current]);
	// Set the scroll animations
	useLayoutEffect(() => {
		gsap.utils.toArray(processList.current.querySelectorAll('li')).forEach(li => {
			gsap.fromTo(
				li as HTMLLIElement,
				{
					opacity: 0,
					y: 80
				},
				{
					ease: 'none',
					opacity: 1,
					y: 0,
					scrollTrigger: {
						trigger: li as HTMLLIElement,
						scrub: 2,
						start: 'top bottom',
						end: '+=200px'
					}
				}
			);
		});
		ScrollTrigger.refresh();
	}, [processList.current]);

	const infoBlocks = INFO_DATA.map((data, index) => {
		const containerStyle = { justifyContent: index % 2 ? 'flex-end' : 'flex-start' };
		const overlayStyle =
			index % 2
				? { left: 0, textAlign: 'left', alignItems: 'flex-start' }
				: { right: 0, textAlign: 'right', alignItems: 'flex-end' };
		const { heading, copy, link, imageUrl } = data;
		return (
			<div key={data.heading} className={[style.block, 'parrallaxBlock'].join(' ')} style={containerStyle}>
				{/* Overlay Y position is changed on scroll */}
				<div className={style.overlay} style={overlayStyle}>
					<h3 className="yellowColour">{heading}</h3>
					<p>{copy}</p>
					{link ? (
						<Link to={link.to} className={style.link}>
							<Button label={link.label} isOutlined isSecondary onPress={() => {}} isLoading={false} />
						</Link>
					) : null}
				</div>
				{/* Image opacity is changed on scroll */}
				<img className={style.image} src={imageUrl} alt={heading} />
			</div>
		);
	});

	return (
		<>
			<SEO title="Unforgettable Holidays with Superior Yachting" />
			<Header dataKey={links.home.link} />
			<section className={style.infoBlocks}>{infoBlocks}</section>

			<section className={style.eaSection}>
				<img src={eaPlane} alt="Private Jet" />

				<div>
					<h3>A Premium Partnership</h3>
					<p>
						We’ve partnered with Excellent Air to help you fly to your charter in style. The Excellent Air crew
						consists of 18 Captains and 18 First Officers. The team are characterized by longterm experience, each
						with an average of 15 years in the industry. The pilots receive regular training by top partners such as
						Lufthansa Aviation Training, where they learn the latest findings regarding safety and procedure. Unlike
						their competitors, Excellent Air employ permanent crews to achieve consistently high levels of quality,
						safety and customer satisfaction. Furthermore, this enables them to be ready for action around the clock,
						365 days a year. The CAMO consists of three highly experienced experts who take care of the fleet
						exclusively, enforcing the highest standards of quality and efficiency in aircraft maintenance, conversion
						and refurbishment.
					</p>
				</div>
			</section>

			<section className={style.process} ref={processSection}>
				<div ref={processImg} className={style.processImg}>
					<img
						id="processImg"
						src="https://cdn.sanity.io/images/o5bo5dba/production/a9f8d1f3a425d0bfa73faad30e221ca8a54c3914-1920x1280.jpg?w=2000&h=2000&fit=max"
						alt="Process"
					/>
				</div>

				<header>
					<Container containerStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
						<h3>Our Service</h3>
						<a href="/SY-Brochure-2020.pdf" target="_blank" rel="noopener noreferrer">
							Download the Brochure
						</a>
					</Container>
				</header>
				<Container>
					<ul ref={processList}>
						{PROCESS_DATA.map((data, i) => (
							<li key={i}>
								<h4>
									<span style={{ opacity: 0.8 }}>{i + 1}. </span>
									{data.heading}
								</h4>
								<p>{data.detail}</p>
								{i === PROCESS_DATA.length - 1 && (
									<NavButton id="contact" title="Get Started" to="/contact" isMobile={false} />
								)}
							</li>
						))}
					</ul>
				</Container>
			</section>
		</>
	);
};

export default IndexPage;
